<template>
    <v-container>
        <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        >
            <v-row>
                <v-col
                cols="12"
                sm="12"
                md="12"
                >   
                    <h2>How do you personally recognize and identify yourself?</h2>
                    <v-divider></v-divider>
                    <v-radio-group
                    v-model="Recognize"
                    row
                    >
                        <v-radio
                            label="Male"
                            value="Male"
                        ></v-radio>
                        <v-radio
                            label="Female"
                            value="Female"
                        ></v-radio>
                            <v-radio
                            label="Lesbian"
                            value="Lesbian"
                        ></v-radio>
                        <v-radio
                            label="Gay"
                            value="Gay"
                        ></v-radio>
                            <v-radio
                            label="Bisexual"
                            value="Bisexual"
                        ></v-radio>
                        <v-radio
                            label="Transgender"
                            value="Transgender"
                        ></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col
                cols="12"
                sm="12"
                md="12"
                >   
                    <h2>How do you present/express yourself?</h2>
                    <v-divider></v-divider>
                    <v-radio-group
                    v-model="Express"
                    row
                    >
                        <v-radio
                            label="In a masculine manner"
                            value="In a masculine manner"
                        ></v-radio>
                        <v-radio
                            label="In a feminine manner"
                            value="In a feminine manner"
                        ></v-radio>
                            <v-radio
                            label="In a masculine and feminine manner"
                            value="In a masculine and feminine manner"
                        ></v-radio>
                       
                    </v-radio-group>
                </v-col>

                <v-col
                cols="12"
                sm="12"
                md="12"
                >   
                    <h2>The gender of people you are sexually/romantically attracted to?</h2>
                    <v-divider></v-divider>
                    <v-radio-group
                    v-model="Attracted"
                    row
                    >
                        <v-radio
                            label="Male"
                            value="Male"
                        ></v-radio>
                        <v-radio
                            label="Female"
                            value="Female"
                        ></v-radio>
                            <v-radio
                            label="Lesbian"
                            value="Lesbian"
                        ></v-radio>
                        <v-radio
                            label="Gay"
                            value="Gay"
                        ></v-radio>
                            <v-radio
                            label="Bisexual"
                            value="Bisexual"
                        ></v-radio>
                        <v-radio
                            label="Transgender"
                            value="Transgender"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col
                cols="12"
                sm="12"
                md="12"
                >   
                    <h2>Are you a?</h2>
                    <v-divider></v-divider>
                    <v-radio-group
                    v-model="LR"
                    row
                    >
                        <v-radio
                            label="Left Handedness"
                            value="Left Handedness"
                        ></v-radio>
                        <v-radio
                            label="Right Handedness"
                            value="Right Handedness"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                 <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >

                    <v-btn
                    :disabled="!valid"
                    color="success"
                    class="mr-4 text--white"
                    @click="savechanges"
                    >
                    Save Changes
                    </v-btn>
                    
                </v-col>
            </v-row>
        </v-form>
        <v-snackbar v-model="snackbar" :timeout="timeout">
        Your changes have been successfully saved!
        <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
            </v-btn>
        </template>
        </v-snackbar>
    </v-container>

</template>

<script>

export default {
    name: 'Health',
    data: () => ({
        snackbar: false,
        timeout: 2000,
        valid: true,
        formTitle:'',
        nameRules: [
            v => v.length > 0 || 'Fields is required',
            v => (v && v.length <= 45) || 'Fields must be less than 45 characters',
        ],
        ageRules: [
            v => (v.length > 0 )|| 'Fields is required',
            v => (v && v <= 2021) || 'Fields must be less than 2 digits',
        ],
        currentYear: (new Date().getFullYear()),
        Recognize: 'Male',
        Express: 'In a masculine and feminine manner',
        Attracted: 'Male',
        LR: 'Right Handedness',
                
      
    }),
    mounted(){
        this.$api.methods.init();
        this.getaddprof()
    },
    methods: {
        
        savechanges () {
     
            //if(this.$refs.form.validate()){
                //
            //}
        
            this.$axios({
                method: 'post',
                url: process.env.VUE_APP_BASE_URL + 'guid/updateaddprof',
                headers: {
                    Authorization: 'Bearer' + localStorage.getItem('usertoken')
                },
                data: {
                    Recognize: this.Recognize,
                    Express: this.Express,
                    Attracted: this.Attracted,
                    LR: this.LR
                }
            })
            .then(response => {
                if(response){
                    this.snackbar = true
                }    
            })
            .catch(error => {
                if (error.response) {
                    if(error.response.status == 500){
                            //
                    }
                }    
            })
        },

        getaddprof(){
            this.$axios({
                method: 'get',
                url: process.env.VUE_APP_BASE_URL + 'guid/addprof',
                headers: {
                    Authorization: 'Bearer' + localStorage.getItem('usertoken')
                }
            })
            .then(response => {
                if(response){
                    this.Recognize = response.data[0].Recognize;
                    this.Express = response.data[0].Express;
                    this.Attracted = response.data[0].Attracted;
                    this.LR = response.data[0].LR;
                }    
            })
            .catch(error => {
                if (error.response) {
                    if(error.response.status == 500){
                            //
                    }
                }    
            })
        }   
    },
    
 
}

</script>

<style>
  .container{
    max-width: 80%;
  }
</style>